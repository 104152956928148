.certification-permissions-modal .modal-dialog {
    font-size: 16px;
    h2 {
        font-size: 16px;
    }
    .card__icon {
        color: #0adfb2;
        margin: 0 5px;
    }
    .btn--aceptar {
        background-color: #01aed9;
    }
}

.pdf-viewport-modal {
    #app {
        display: flex;
        flex-direction: column;
        height: 100vh;
      }
      #toolbar {
        display: flex;
        align-items: center;
        background-color: #555;
        color: #fff;
        padding: 0.5em;
      }
      #toolbar button,
      #page-mode input {
        color: currentColor;
        background-color: transparent;
        font: inherit;
        border: 1px solid currentColor;
        border-radius: 3px;
        padding: 0.25em 0.5em;
      }
      #toolbar button:hover,
      #toolbar button:focus,
      #page-mode input:hover,
      #page-mode input:focus {
        color: lightGreen;
      }
      #page-mode {
        display: flex;
        align-items: center;
        padding: 0.25em 0.5em;
      }
      
      #viewport-container {
        flex: 1;
        background: #eee;
        overflow: auto;
      }
      #viewport {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
      #viewport > div {
        text-align: center;
        max-width: 100%;
      }
      #viewport canvas {
        width: 100%;
        box-shadow: 0 2px 5px gray;
      }

}
.helper-modal {
    display: flex !important;
    flex-wrap: wrap;
    align-content: flex-end;
    .modal-dialog {
        width: 100%;
        margin: 0;
    }
    .col-sm-3 {
      /*   width: 30%; */
    }
    .modal-header {
        background-color: $greenH ;
        /* background: linear-gradient(to right, $gray-davivienda 76%, $redDavivienda 30%); */
       
        padding: 0;
        .col-sm-9 {
           /*  width: 70%; */
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            height: 100%;
            .col-md-7 {}
            .col-md-4 {
                display: flex;
                justify-content: flex-end;
            }
            .search__helper {
                display: flex;
                justify-content: center;
                align-items: center;
                input:focus {
                    outline: none;
                }
                #search {
                    height: 4.4rem;
                    width: 22rem;
                    border-style: solid;
                    border-color: #e9ffff;
                    border-radius: 20px;
                    background-color: unset;
                    justify-content: center;
                    font-size: medium;
                }
                /* position: relative;
				height: 4.4rem;
				width: 29rem;
				border-style: solid;
				border-color: #e9ffff;
				border-radius: 20px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				padding: 1rem; */
                .iconH-SH_Buscador::before {
                    top: 23%;
                    margin-left: -4rem;
                    color: #e9ffff!important;
                }
            }
        }
        h2 {
            margin: 10px;
            color: $white;
        }
        .card__inner {
            padding: 0;
            .card__title {
                line-height: 23px;
            }
            line-height: 1;
            .r-flex {
                display: flex;
                align-items: center;
                div>div {
                    color: #000;
                }
            }

            .select--helper{

             height: 3.8em;
                .select2-results .select2-result-label{
                    padding: 7px 15px;
                    min-height: 31px;
                    font-size: .9em;
                }
                .select2-container .select2-choice {
                    color: $white;
                    border: none;
                    height: auto;overflow: visible;
                }
                .select2-drop {
                    background-color: $gray-davivienda;
                    border-radius: 8px;
                    color: $white;
                }
                .select2-search{
                    input[type=search] {
                      border-radius: 8px;  
                    }
                }
                .select2-arrow b {
                    background: none ;
                    width: 103px;
                   color: red;
                }

                .select2-choice > .select2-chosen{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-right: 0;
                    overflow: visible;
                    white-space: normal;
        line-height: 1;
                    .iconH-SH_Buscador:before{
                        color: white;
                    }
                }
                .select2-results .select2-highlighted{
                    background-color: $calc-inventory;
                    color: $white;
                }
                .ui-select-choices-row:hover{
                    background-color: $calc-inventory;
                }
            } 

        }
        .helper-header-flex {
            display: flex;

            align-items: center;
            justify-content: flex-start;
            background: $blue;
            height: 100%;
            .iconH-SH_Centro-de-ayuda::before {
                font-size: 4rem;
                color: #fff;
            }
        }
    }
    .modal-body {
        padding: 5px;
        margin-top: 10px;
        height: 325px;
        .r-flex {
            display: flex;
            align-items: unset;
        }
        .col-sm-9 {
                    
    padding-right: 0;
    padding-left: 3rem;
            padding-left: 3rem;
            .no-data-helper{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                height: 62%;
                width: 60%;
                margin-top: 4rem;
                margin-left: 20%;
                border-style: inset;
                border-width: 3px;
                border-color: $gray-davivienda;
                border-radius: 1rem;
                flex-direction: column-reverse;
                > p{
                    font-size: 22px;
                    font-weight: bold;
                    text-align: center;
                }
                > img {
                    width: 80px;
                    margin-bottom: 2rem;
                    height: auto;
                }
            }
            .notificatio__option {
                #helper-container-body {
                    .notification {
                        .card__inner::before {
                            left: 9.5px;
                        }
                    }
                    .help {
                        border: #000;
                    }
                }
                #helper-container-body>.title-body {
                    padding: 0;
                    padding-bottom: 2rem;
                    color: $white;
                    display: flex;
                    align-items: flex-start;
                    div:first-child {
                        padding-right: 1.2rem;
                    }
                    label {
                        background-color: $gray-davivienda;
                        width: 27px;
                        height: 27px;
                        border-radius: 50%;
                        text-align: center;
                    }
                }
            }
            .retro__option {
                display: flex;
                #helper-container-body {
                    width: 50%;
                    .funcionality__container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: 100%;
                    }
                    .check__container {
                        width: 100%;
                        height: 100%;
                        margin-top: 2rem;
                    }
                    .check__functionality {
                        margin: 5px;
                    }
                    .check__text {
                        font-size: 15px;
                        color: #000;
                    }
                    input[type="checkbox"]:checked {
                        box-shadow: inset 0 0 124px 55px rgb(0, 0, 0);
                        background: none;
                    }
                    .ticket__container {
                        display: flex;
                        flex-direction: column;
                        height: 80%;
                        .subtitle {
                            color: #000;
                            margin-right: 3px;
                        }
                        .chat__container {
                            display: flex;
                            flex-direction: column;
                            width: 90%;
                            height: 100%;
                            border: 4px solid #E4E4E4;
                            border-radius: 8px;
                            margin-top: 10px;
                            .text__container {
                                padding-left: 3px;
                                font-weight: bold;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .text {
                                    height: 100%;
                                    width: 100%;
                                    border: none;
                                    outline: none;
                                }
                            }
                            .buttons__container {
                                display: flex;
                                border-top: 4px solid #e4e4e4;
                                justify-content: flex-end;
                                width: 100%;
                                height: 4rem;
                                .button__send {
                                    display: flex;
                                    width: 70px !important;
                                    background-color: $blue;
                                    border: none;
                                    color: #fff;
                                    margin-right: 8px;
                                    font-weight: 700;
                                    padding: 4px;
                                    height: 100% !important;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }
                
                .card__inner {
                    background-color: #f9fbfc;
                    margin-top: 30px;
                }
                .card__inner+.card__inner {
                    margin-top: 4rem;
                }
                .container__radio {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .star-widget input{
                        display:none;
                     }
                     .star-widget label{
                        font-size:40px;
                        color: grey;
                        padding:10px;
                        float:right;
                        transition:all 0.2s ease;
                     }

                     label:hover,
                 label:hover~label {
                     color: $star-yellow;
                 }


                 input[type="radio"]:checked~label {
                    color: $star-yellow;
                 }
                 }
                
                 input[id^="rate-5"]:checked~label {
                    color:#fe7;
                    text-shadow: 0 0 20px #952;
                 }
                

                .btn__retro__option {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    margin-top: 2%;
                    margin-right: 16px;
                    .btn__container {
                        background-color: transparent;
                        color: $black;
                        border: 1.5px solid  $gray-davivienda;
                        border-radius: 4px;
                        padding-left: 5%;
                        padding-right: 5%;
                        height: 50px;
                        width: 250px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: medium;
                        margin-bottom: 4%;
                        p {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #000;
                            span.icon__chat {
                                color: $greenH !important;
                                font-size: 25px;
                                margin-right: 10px;
                            }
                        }
                        span {
                            color: $greenH !important;
                        }
                    }
                    .btn__container__selected {
                        background-color: $greenH;
                        color: $white;
                        border: 1.5px solid  $greenH;
                        border-radius: 8px;
                        padding-left: 5%;
                        padding-right: 5%;
                        height: 50px;
                        width: 250px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: medium;
                        margin-bottom: 4%;
                        p {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: $white;
                            span.icon__chat {
                                color: $white !important;
                                font-size: 25px;
                                margin-right: 10px;
                            }
                        }
                        span {
                            color: $white !important;
                        }
                    }
                    .social__media__container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        align-items: flex-start;
                        .title__media {
                            margin-left: 20px;
                            color: #000;
                            font-size: 20px;
                        }
                        .social__icons {
                            display: flex;
                            justify-content: space-around;
                            margin-left: 10px;
                            .icon__size {
                                border-radius: 5px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #000;
                                margin: 5px;
                                font-size: 30px;
                            }
                            .icon__size_ins {
                                display: flex;
                                margin-top: 7px;
                                margin-left: 4px;
                                justify-content: center;
                                align-items: center;
                                background-color: #000;
                                padding: 1%;
                                font-size: 22px;
                                border-radius: 6px;
                                max-height: 26px;
                                width: 27px;
                            }
                        }
                    }
                }
            }
            .help__option {
                #helper-container-body {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .ticket__container {
                        display: flex;
                        flex-direction: column;
                        height: 80%;
                        .subtitle {
                            color: #000;
                            margin-right: 3px;
                        }
                        .chat__container {
                            display: flex;
                            flex-direction: column;
                            width: 355px;
                            height: 100%;
                            border: 4px solid #E4E4E4;
                            border-radius: 8px;
                            margin-top: 10px;
                            .text__container {
                                padding-left: 3px;
                                font-weight: bold;
                                width: 350px;
                                height: 80%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                .text {
                                    height: 100%;
                                    width: 100%;
                                    border: none;
                                    outline: none;
                                }
                            }
                            .buttons__container {
                                display: flex;
                                border-top: 4px solid #e4e4e4;
                                justify-content: space-between;
                                width: 100%;
                                height: 35px;
                                .button__attach {
                                    margin-left: 5px;
                                    width: 80px;
                                    margin: 3px;
                                    background-color: #FCFFFF;
                                    border: none;
                                    border-radius: 3px;
                                    color: #1F4D74;
                                    font-weight: bold;
                                }
                                .button__send {
                                    width: 70px;
                                    background-color: $redDavivienda;
                                    border: none;
                                    color: $white;
                                    margin: 3px;
                                    margin-right: 8px;
                                    border-radius: 10px;
                                    font-weight: bold;
                                    height: 100%;
                                }
                            }
                        }
                    }
                    .questions__container {
                        //display: flex;
                        width: 50%;
                        h3 span {
                            color: $greenH;
                        }
                        .subtitle {
                            color: #000;
                            margin-right: 3px;
                        }
                        .questions {
                            align-items: center;
                            width: 350px;
                            height: 125px;
                            color: #000;
                            uib-accordion {
                                div {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
            #helper-container {
                position: relative;
            }
            #helper-container-body {
                top: -16px;
                height: 32rem;
                overflow-y: auto;
                overflow-x: hidden;
                scrollbar-color: $gray-davivienda transparent;
                scrollbar-width: thin;
                .content_videos {
                    display: flex;
                    flex-direction: column;
                    iframe {
                        width: 37rem;
                        height: 20rem;
                    }
                    .description_video {
                        padding: 0px 47px;
                        h3 {
                            color: #000;
                        }
                        p {
                            text-align: justify;
                            color: black;
                        }
                    }
                    .videos {
                        display: flex;
                    }
                    .videos+.videos {
                        padding-top: 4rem;
                    }
                }
                .content_document {
                    display: flex;
                    flex-direction: column;
                    .document {
                        display: flex;
                        align-items: baseline;
                        .card__title__helper {
                            font-size: 19px;
                            padding-bottom: 1.5rem;
                            line-height: 1;
                        }
                    }
                    .document>div {
                        display: flex;
                        align-items: baseline;
                        span {
                            padding-right: 1rem;
                        }
                    }
                    .iframe--document{      
                        position: absolute;
                        left: 35%;
                        top: -100%;
                        width: 740px;
                        height: 490px;
                        z-index: 0;
                        overflow: hidden;
                        outline: 0;
                    
                    }
                }
                .content_dictionary {
                    .card__title__helper {
                        font-size: 19px;
                        margin-bottom: 0;
                        line-height: 23px;
                    }
                    list-style: none;
                    /* Quitamos los marcadores */
                    padding: 0;
                    /* Quitamos el padding por defecto de la lista */
                    li {
                        display: flex;
                        align-items: flex-start;
                        padding-bottom: 1.5rem;
                    }
                    li::before {
                        content: "\2022";
                        padding-right: 8px;
                        color: $black;
                        font-size: 50px;
                        line-height: 25px;
                    }
                }
                .notification {
                    //padding: 0 40px;
                    .notification_body {
                        padding-left: 4rem;
                        display: flex;
                        align-items: center;
                        .btn {
                            background-color: $redDavivienda;
                            min-width: 94px;
                            max-width: 230px;
                            border-radius: 1rem;
                            padding: 1.3rem;
                            .iconH-SH_Retroalimentacion:before {
                                content: "\e907";
                                color: $white;
                                font-size: 24px;
                            }
                        }
                        .col-md-4 {
                            display: flex;
                            justify-content: end;
                        }
                    }
                }
                .notification+.notification {
                    padding-top: 2rem;
                }
            }
            #helper-container-body::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }
            #helper-container-body::-webkit-scrollbar-track {
                background-color: transparent;
            }
            #helper-container-body::-webkit-scrollbar-thumb {
                background-color: $blue;
            }
            .menuRcs {
                li {
                    display: inline;
                    padding-left: 3px;
                    padding-right: 3px;
                }
                li:last-child {
                    border-right: none;
                }
                a {
                    padding: 5px;
                    color: #252424;
                    text-decoration: none;
                }
                li>a:hover {
                    cursor: pointer;
                    border-radius: 20px;
                }
                .resource__selected {
                    color: white;
                    background-color: $greenH;
                    cursor: pointer;
                    border-radius: 20px;
                }
            }
        }
        .title-body {
            color: #000;
            display: flex;
            align-items: baseline;
        }
        h3>p {
            margin-top: 20px;
            margin-bottom: -22px;
        }
        .card__inner {
            .feedback__form{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            &::before {
                content: '';
                height: 100%;
                width: 4px;
                position: absolute;
                top: 0;
                left: 0px;
                background-color: $greenH;
            }
            padding: 0px 40px;
            .card__title__helper {
                margin-top: 20px;
                font-weight: normal;
                line-height: 20px;
                font-size: 20px;
                text-align: justify;
                color: #414141;
            }
        }
        .card__inner+.card__inner {
            border-top: 0;
        }
        .menu {
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: 100%;
            //background-color: #f1f1f1;
            height: 100%;
            li>.row {
                padding-left: 0.8rem;
                .icon-size {
                    width: 44px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                p {
                    padding-left: 2rem;
                }
            }
            li a {
                display: flex;
                color: #000;
                padding: 8px;
                text-decoration: none;
            }
            li a:hover {
                display: flex;
                padding: 8px;
                text-decoration: none;
                cursor: pointer;
            }
            .click__menu__option {
                background-color: $greenH;
                color: white;
                transition: .3s;
                border-radius: 20px 0px 0px 20px;
                .iconH-SH_Consejos:before {
                    color: $white;
                }
                .iconH-SH_Recursos:before {
                    color: $white;
                }
                .iconH-SH_Ayuda:before {
                    color: $white;
                }
                .iconH-SH_Notificaciones:before {
                    color: $white;
                }
                .iconH-SH_Retroalimentacion:before {
                    color: $white;
                }
            }
        }
    }
    
}

.modal--simple {
    .modal-dialog {
        width: 90%;
        max-width: 780px;
        margin: 0 auto;
        max-height: 70vh;
    }
    .modal-content {
        text-align: center;
    }
    .modal-header {
        background-color: $gray-davivienda;
        color: $white;
        border: none;
    }
    .modal-inner {
        padding: 15px;
    }
    .close {
        color: $pink;
    }
    h2 {
        font-size: 26px;
        margin-bottom: 15px;
        &+p {
            font-size: 16px;
            margin-bottom: 40px;
        }
    }
}

.modal-header .close {
    margin-top: 3px;
}

.modal-content {
    border-radius: 0;
}

.modal-header {
    background-color: $turq;
    color: #fff;
    padding: 15px 60px 15px;
    position: relative;
}

.modal-title {
    font-size: 22px;
}

.modal-body {
    .modal-btns {
        display: flex;
        align-items: baseline;
    }
}

.paddinModal {
    //padding: 9px;
    .card__inner {
        padding: 0px;
    }
}

.modal-footer {
    text-align: center;
    border: none;
}

.close {
    font-size: 16px;
    opacity: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    &:focus {
        outline: none;
    }
}


/* start-certification {

	.modal-dialog & {
		width: 900px;
	margin: 30px auto;
	}
} */

start-certification>.modal-body {
    /* form>div {
        display: flex;
        flex-wrap: wrap;
    } */
    form>div>div {
      
        margin: 1rem;
    }
    .modal-btns{
        display: flex;
    align-items: baseline;
    justify-content: space-around;
    }
}

form-indicator-record>.modal-body {
    form>div {
        display: flex;
        flex-wrap: wrap;
    }
    form>div>div {
        width: calc(50% - 2rem);
        margin: 1rem;
    }
}

.modal .modal-dialog {
    /* width: 900px;
	margin: 30px auto; */
}

@media screen and (min-width: 768px) {
    .certification-permissions-modal .modal-dialog {
        width: 750px;
    }
    .certification-test-modal .modal-dialog .modal-body {
        padding: 30px 25px;
    }
    start-certification .modal-body {
        padding: 0px 60px;
        //padding: 30px 60px;
        /* padding: 9px; */
    }
    .modal--simple {
        .modal-inner {
            padding: 0 60px 60px;
        }
    }
}

.modal-toggle-autocomplete {
    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            margin-bottom: 2rem;
        }
        #confirmation-message,
        #toggle-message {
            color: $blue-dark !important;
            font-size: 2.3rem;
        }
    }
    .modal-footer.modal-btns {
        button {
            margin: 6px auto;
            max-width: 90%;
            font-size: 2rem;
        }
    }
}

#modal-agregar-evidencias {
    .modal-header {
        button {
            color: white;
        }
        .modal-title {
            font-size: 2rem;
        }
        #div-col-mes-modal-agregar-evidencias {
            background-color: white;
            width: 11.1em;
            border: solid 2px gray;
            float: right;
            font-size: 1.3rem;
            margin-top: 1em;
            label {
                color: $black;
                margin-right: 0.5em;
                padding: 5px;
                margin: 0;
            }
            select {
                height: 100%;
                padding: 3px;
                margin: 1px;
                border: none;
                float: right;
            }
        }
    }
    .modal-body {
        .row {
            margin-top: 1em;
            margin-bottom: 1em;
        }
        #subir-evidencia {
            span {
                margin: 0.5em;
            }
            input[type="file"] {
                display: none;
            }
        }
    }
    .modal-footer {
        .modal-btns {
            margin-top: 50px;
            button {
                padding: 6px;
                margin: 6px auto;
            }
        }
    }
}

#modal-descargar-evidencias {
    .modal-header {
        button {
            color: white;
        }
        h3 {
            float: left;
        }
    }
    .modal-body {
        max-width: 30em;
        margin: auto;
        .modal-body-content {
            .row {
                margin-top: 1em;
                margin-bottom: 2em;
                div input,
                div select {
                    border-radius: 0;
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    box-shadow: none;
                }
            }
        }
    }
    .modal-footer .modal-btns {
        margin-top: 50px;
        div button {
            padding: 6px;
        }
    }
    #btn-evidences-zip {
        margin-bottom: 1em;
    }
}

.msg-error {
    color: crimson;
}

.col-fecha {
    img {
        float: left;
        margin: 0.5em;
    }
    input {
        width: calc(100% - 35px);
        float: right;
    }
}

#evidencias {
    display: flex;
    flex-wrap: wrap;
    max-height: 20em;
    overflow-y: auto;
    .item-evidencia {
        display: flex;
        flex-direction: column;
        border-radius: 3%;
        border: #ccc solid 1px;
        width: 12em;
        margin: 0.5em;
        a div.div-image {
            border-radius: 3% 3% 0% 0%;
            width: 100%;
            height: 10em;
            border-bottom: #ccc solid 1px;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}

.dropdown {
    * {
        cursor: pointer;
    }
    .btn-evidence {
        padding: 0.3em;
        width: 100%;
    }
    span {
        margin-left: 1em;
        font-size: 2rem;
        color: $turq;
    }
    .dropdown-item div {
        width: 12em;
        img {
            float: right;
        }
    }
}

#modal-eliminar-evidencia,
#modal-cambiar-toggle-evidencia {
    .modal-body {
        text-align: center;
        img {
            padding-bottom: 1em;
        }
        h2 {
            color: $blue-dark !important;
            font-size: 2.3rem;
        }
    }
    .modal-footer button {
        margin: 0.5em auto;
        max-width: 90%;
        font-size: 2rem;
    }
}

.modal-loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid $turq;
    width: 40px;
    height: 40px;
    -webkit-animation: spiner 2s linear infinite;
    /* Safari */
    animation: spiner 2s linear infinite;
}

.no-evidence-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 180px !important;
    border-bottom: 1px solid #ccc;
    span {
        font-weight: 700;
        font-size: 14px;
    }
}


/* Safari */

@-webkit-keyframes spiner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spiner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}