.aside {
	display: none;
	position: fixed;
	background-color: #FFF;
	width: 100%;
	height: 100%;
	max-width: 545px;
	top: 0;
	right: 0;
	z-index: 10;
	overflow: scroll;
}

.aside--large {
	max-width: 794px;
}

.aside__head {
    background-color: $blue;
    color: #FFF;
    padding: 25px 40px;
    position: relative;
    h2 {
        font-size: 26px;
        display: inline-block;
        vertical-align: middle;
    }
	.size-img {
		width: 27px !important;
		padding-bottom: unset;
		vertical-align: top;
	}
    [class^="fa"] {
        color: #FFF;
        font-size: 16px;
        border: 2px solid #FFF;
        border-radius: 50%;
        padding: 5px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }
}

.aside__section {
	padding: 30px 60px;	
}


.aside__table {
	width: 100%;
	margin: 20px 0 25px;
	font-size: 16px;
	color: #6C6C6C;
	td {
		padding: 5px 0;
		text-align: center;
		&:first-child {
			text-align: left;
		}
	}
	tr {
		border-bottom: 1px solid #D5DBDD;
	}
	a {
		color: #6C6C6C;
	}
}

.aside__title {
	font-size: 22px;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		height: 100%;
	    width: 4px;
	    left: -60px;
	    background-color: #0ADFB2;
	    top: 0;
	}
}

.aside__back {
	display: inline-block;
	margin-right: 30px;
	[class^="icon-"] {
		color: #FFF;
		font-size: 16px;
		border: 2px solid #FFF;
		border-radius: 50%;
		padding: 5px 6px 5px 4px;
		text-align: center;
		display: inline-block;
		vertical-align: middle;
	}
}

.aside__close {
	display: block;
	color: #FFF;
	position: absolute;
	right: 25px;
	top: 25px;
	cursor: pointer;
	&:hover {
		text-decoration: none;
	}
}

.aside__edit {
	display: inline-block;
	cursor: pointer;
	.back-direction{
		vertical-align: top;
	}
	.fa-pencil-square-o{
		color: white;
	}
}

.aside__list {
	list-style-type: none;
	padding: 0;
	margin: 0;
	[class^="icon-"] {
		font-size: 42px;
		display: inline-block;
		vertical-align: middle;
		margin: 0 40px;
	}
	li {
		border-bottom: 1px solid rgba(75,75,75, 0.08);
	}
	a {
		span{
			margin: 0 40px;
		}
	//	display: block;
	display: flex;
	align-items: center;
		padding: 12px 0;
		color: $black;
		cursor: pointer;
		&:hover {
			text-decoration: none;
			background-color: $gray-light;
		}
	}
}