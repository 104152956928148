.loader-container {
    z-index: 9999;
    .modal {
        display: block;
    }
    .loader {
        background-image: url(../img/logos/logo-susty-outline.png);
        background-repeat: no-repeat;
        position: absolute;
        top: calc(50% - 100px);
        left: calc(50% - 100px);
        background-position: center center; 
        border: 16px solid transparent;
        border-radius: 50%;
        width: 200px;
        height: 200px;
        animation: spin 1s linear infinite;
    }
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    0% {border-top: 16px solid #3498db;}
    33% { border-right: 16px solid green;}
    66% { border-bottom: 16px solid yellow;}
    100% { border-left: 16px solid red; }
    
}