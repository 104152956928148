.input-label {
	font-size: 18px;
	display: block;
	margin-bottom: 10px;
}

.input, .input-monthly-group {
	border: none;
	border-bottom: 1px solid $gray-light;
	display: block;
	width: 100%;
padding-top: 30px;

	margin-bottom: 20px;
	resize: none;
	&:focus {
		outline: none;
		border-color: $blue;
	}
}

.input--inline-check {
  display: inline-block;
  width: 60%;
  margin-left: 5%;
}

.form-box {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }
	margin: 15px 0;
  display: inline-block;
  width: 100%;
  .gra-flex{
    width: 22%;
  }
  .nav-tabs.nav-justified {
        
    position: unset !important;
    margin-top: unset !important;
    left: unset !important;
}
  .select2-choice{
    border: 1px solid #aaa !important;
  }
  &.no-margin {
    margin: 0;
  }
}

.input-file {
  display: none!important;
}

.input-file-label {
    font-weight: 400;
    font-size: 12px;
    padding: 5px;
    display: inline-block; 
    cursor: pointer;
    [class^="fa"] {
        font-size: 17px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }
    &.transparent {
        background-color: transparent;
    }
    &.gray {
        background-color: $gray-light;
    }
}

//RADIO

.radio {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

.radio + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  font-size: 18px;
  font-weight: normal;
  transition: .28s ease;
  -khtml-user-select: none;
  user-select: none;
}

.radio + label:before,
.radio + label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 2px 4px;
  width: 20px;
  height: 20px;
  z-index: 0;
  transition: .28s ease;
  border-radius: 50%;
}

.radio:not(:checked) + label:before,
.radio:not(:checked) + label:after {
  border: 2px solid $black;
}

.radio:not(:checked) + label:after {
  z-index: -1;
  transform: scale(0);
}

.radio:checked + label:before,
.radio:checked + label:after {
  border-color: $blue;
}

.radio:checked + label:before {
  border: 2px solid $blue;
}

.radio:checked + label:after {
  background-color: $blue;
  z-index: 0;
  transform: scale(.5);
}

//CHECKBOX

.checkbox{
  position: absolute;
  left: -9999px;
  opacity: 0;
}

.checkbox {
  + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 22px;
  	line-height: 22px;
  	font-size: 18px;
  	font-weight: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
  }

  + label:before,
  &:not(.filled-in) + label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 2px solid $black;
    border-radius: 1px;
    margin-top: 2px;
    transition: .2s;
  }
}

.checkbox:checked {
  + label:before {
    top: -4px;
    left: -5px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: $blue;
    border-bottom: $blue;
    transform: rotate(40deg);
    backface-visibility: hidden;
    transform-origin: 100% 100%;
  }

}


.checkbox {
  + label:after {
    border-radius: 2px;
  }

  + label:before,
  + label:after {
    content: '';
    left: 0;
    position: absolute;
    transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
    z-index: 1;
  }

  // Unchecked style
  &:not(:checked) + label:before {
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: 10px;

    -webkit-transform: rotateZ(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 20% 40%;
    transform-origin: 100% 100%;
  }

  &:not(:checked) + label:after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid #56b54a;
    border-radius: 100%;
    top: 0px;
    z-index: 0;
  }

  &:checked {
    + label:before {
      top: 2px;
      left: 1px;
      width: 8px;
      height: 13px;
      border-top: 2px solid transparent;
      border-left: 2px solid transparent;
      border-right: 2px solid #FFF;
      border-bottom: 2px solid #FFF;
      -webkit-transform: rotateZ(37deg);
      transform: rotateZ(37deg);

      -webkit-transform-origin: 100% 100%;
      transform-origin: 100% 100%;
    }

    + label:after {
      top: 0;
      width: 20px;
      height: 20px;
      border: 2px solid #56b54a;
      background-color: #56b54a;
      border-radius: 100%;
      z-index: 0;
    }
  }
}

// SELECT

.label--select {
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.select {
  .select2-container .select2-choice .select2-arrow {
    border: none;
    background: none;
  }
  .select2-container .select2-choice {
    background: none;
  /*   height: 33px;
    line-height: 33px; */
    line-height: 25px;
    height: 25px;
  }
  .select2-container .select2-choice .select2-arrow {
    b {
     /*  background-position: 0px 5px; */
      background-position: 0 -1px;
    }
  }
}

.select, .select-year {
  margin: 10px 0;
  .select2-drop {
    border-radius: 0;
    border: none;
  }
  .select2-results .select2-highlighted {
    background-color: $gray-light;
    color: $black;
  }
}

.select-year {  
  font-size: 15px;
  .select2 {
    width: 100%;
    min-width: 150px;
  }
  .select2-container .select2-choice .select2-arrow {
    border: none;
    background: none;
    display: none;
  }
  .select2-container .select2-choice {
    background: none;
    height: 40px;
    line-height: 50px;
  }
  .select2-result-label {
    height:30px;
    border-bottom: 1px solid $gray-lighter;
  }
  .chevron-down {
    color: $gray;
    margin-left: -30px;
    -webkit-text-stroke: 1px $white;
  }
  .year-span {
    margin-right: -50px;
    font-weight: bold;
    margin-left: 10px;
  }
  .year-span-selected {
    margin-left: 50px;
    display: flex;
    margin-top: -7px;
    color: $gray;
  }
}

.select--inline {
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
  &.year {
      min-width: 80px;
      //padding-right: 4rem;
      .select2-container, input {
          width: 100% !important;
          cursor: pointer;
      }
  }
  &.denominator {
      min-width: 140px;
      padding-right: 0px;
      .select2-container, input {
          width: 100% !important;
          cursor: pointer;
      }
  }
}

.select--box {
  .select2-container .select2-choice {
    border: 2px solid $gray-dark;
    border-radius: 8px;
  }  
}

.select--block {
  .select2-container {
    display: block;
    width: 100%;
    /* font-size: 0.9rem;
    margin-left: 10px; */
  }
}

.select--line {
  .select2-container .select2-choice {
    border: none !important ;
    border-bottom: 1px solid $gray-light !important;
    border-radius: 0;
    padding: 0;
  }
}

.select2-container-active .select2-choice, .select2-container-active .select2-choices {
  @include box-shadow (0 , 0 , 0 , 0 , transparent);
}

// TABLES
.row-type {
  font-size: 1.1rem;
  font-weight: bold;
  color: $black;
}

.measure-and-currency-block {
  display: flex;
  justify-content: space-evenly;  

  @media screen and (max-width: 83.75rem) {
    flex-direction: column;
  }
}

.measure-and-currency-inner {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 83.75rem) {
    align-items: center;
  }
}