.header {
    .navbar {
        background: #FFFFFF;
        box-shadow: 0px 1px 15px 0px #D7EAF7;
        border: none;
        margin: 0;
        min-height: 60px;

        .container_header{
            display: flex;
            align-items: center;
            min-height: 80px;
            width: 100%;
            justify-content: space-between;
            padding-left: 66px;
            padding-right: 29px;

            .navbar-right > li > a {
                color: white;
                line-height: unset;
            }
        }
        
        #top--scroll{
           .container-fluid{
                display: flex;
                align-content: center;
                background-color: #ad0000;
                
            }
        }
        
        
        
        .navbar-brand {
            padding-top: 7px;
        }
        
        .navbar-toggle {
            .icon-bar {
                background-color: #01AED9;
            }
            border: none;
            margin: 0;
            border-left: solid 1px rgba(90,139,172,.2);
            border-radius: 0;
            height: 50px;
        }
    }
}

.header-inner {
    @include box-shadow(0,1px,15px,0,#D8EBF8);
    position: relative;
    [class^="fa"] {
        color: $blue;
        display: inline-block;
        vertical-align: middle;
        padding-right: 1rem;
    }
    .navbar {
        background-color: #FFF;
        margin: 0;
        border-radius:0 ;
        .container_header{
            display: flex;
            align-items: center;
            min-height: 80px;
            width: 100%;
            justify-content: space-between;
            padding-left: 66px;
            padding-right: 29px;
        }

     
    }
    #top--scroll{
        #header-list{
            padding-left: 40px;
            .active{
                a{
                    border-bottom: solid 2px white;
                }
              
                
            }
        }
        #content-nav{
            width: 78%;
           
        }
        .container-fluid{
             display: flex;
             align-items: center;
             padding-left: 8.5rem;
             padding-right: 8.5rem;
             height: 80px;
         }

         .logo-responsive{
            background: url(../img/content/bullet-menu-empresas.png) -5px -5px no-repeat;
         }

         .nav .open>a {
            background-color: $gray-davivienda;
         }
         .dropdown-menu{
            display: none;
            background-color: $gray-davivienda;
            border-radius: 0 0 10px 10px;
            li {
               .active{
                background-color: #ad0000;
               }
                a {
                    min-height: 36px !important;
                    display: block;
                    &:hover {
                        background-color: #595959;
                        background-image: none;
                       
                    }
                }
            }
         }
         .dropdown:hover  {
            > .dropdown-menu{
                display: block;
            }
          
            @media only screen and (min-width: 766px) {
                .icono-bullet{
                    background-position: -58px -10px !important;
                    
                }  
            }
           
        }

        @media only screen and (max-width: 766px) {
            .container-fluid{
                display: flex;
                align-items: center;
                margin-left: unset;
                padding-left: 15px;
                
            }
            #header-list{
                padding-left: unset;
              
                
            }
           .logo-davivienda{
            background-image: url("../fonts/logo-casa-delineado.svg");
            width: 53px;
            height: 44px;
           }
           .navbar-header{
            width: 100vh;
            margin-left: 0;
            .navbar-toggle {
                margin-right: 0;
            }
           }
           .navbar-nav > .open {
            .dropdown-menu{
                display: block !important;
                
                
               }
           } 
           .dropdown {
            width: 95%;
            .icono-bullet{
                background-position: -5px -5px;
              
                right: 120px !important;
            }
            
            }
             .open{
                .icono-bullet{
                    background-position: -58px -10px !important;
                
                    right: 120px !important;
                }
             }
           .dropdown:hover  {
            > .dropdown-menu{
                display: none;
                
            }
            
        }
           #content-nav{
            width:90%;
            max-width:1000px;
            margin:20px auto;
            background: $gray-davivienda;
            position: absolute;
            z-index: 999;
            top: 48%;
            left: -1px;
            display: none;
            .nav{
                margin-left: 10px;
            }
           }
           
        }
     }
    .navbar-brand {
        padding: 5px;
    }
    .navbar-nav {
        li {
            border-right: 1px solid rgba(90,139,172,0.2);
            &:first-child {
                border-left: 1px solid rgba(90,139,172,0.2);
            }
        }
    }
}

.navbar-nav {
    li {
        a {
            color: $black;
        }
       
        .btn--border-turq {
            text-transform: uppercase;
            color: $turq;
            line-height: 36px;
            padding: 0 15px;
            margin-top: 5px;
            border-radius: 10px;
        }
    }
}

.navbar__title {
    padding: 15px;
    position: absolute;
    top: 0;
    left: 25%;
    display: none;
    @media screen and (min-width: 768px) {
        display: block;
    }
}

.footer {
    background-color: $white;
    padding: 20px 0;
    @media screen and (max-width: 768px) {
        .icon--footer{
            background-image: url("../fonts/logo-casa-delineado.svg") !important;
            width: 53px !important;
            height: 44px !important;
            margin-right: 25px;
        }
        .text-principal{
            text-align: justify !important;
        }
    }
    .icon--footer{
        background-image: url("../img/logos/logo-davivienda.png");
        width: 310px;
        height: 59px;
    }
    
    .logo-susty {
        width: 105px;
        height: 31px;
        display: inline-block;
        vertical-align: middle;
    }
    a {
        color: $black;
    }
  
}