.calc-container {
    padding: unset !important;
    margin-bottom: 5px;
    .row {
        width: 100%;
        height: 100%;
        margin: unset ;
        .col-lg-9 {
            padding: 0;
        }
    }
}

.calc-header {
    display: flex;
    background: $white;
    color: $black;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5px;
    margin: 0;
    border-bottom: solid;
    align-items: center;
    .size-img {
        width: 29px!important;
        padding: unset;
        margin: 0 1rem;
        color: $turq;
        font-size: 2.6rem;
        padding-top: 3px;
    }
   // box-shadow: 0px -6px 7px 1px rgba(0,0,0,0.3);
    //-webkit-box-shadow: 0px -6px 7px 1px rgba(0,0,0,0.3);
 //   -moz-box-shadow: 0px -6px 7px 1px rgba(0,0,0,0.3);
    
    .title {
        padding-right: 2%;
        border-right: 10px solid $turq;
        margin-right: 2%;
    }
    .subtitle {
        color: $black;
    }
}

.dashboard {
    background: $calc-dashboard;
    color: #000;
    height: 560px;
    overflow: scroll;
    
    min-height: calc(100vh - 165px);
    &::-webkit-scrollbar {
        display: none;
    }
    .menu {
        display: flex;
        flex-direction: column;
        padding: 3px;
        color: black;
        uib-accordion {
            background-color: unset;
        }
       
        .accordion {
            a,
            a:hover,
            a:active,
            a:visited,
            a:focus {
                text-decoration: none;
                outline: 0;
            }
            .panel-body {
                padding: 0;
                border-style: none;
                margin-top: 8px;
            }
            .panel-heading {
                text-decoration-color: none;
                box-shadow: unset;
            }
            .info-style {
                margin-bottom: 10px;
                border-left: 7px solid $calc-info;
                padding-left: 5px;
                background-color: $calc-dashboard;
            }
            .info-style-selected {
                background-color: $calc-info;
                color: #fff;
                padding-left: 5px;
            }
            .emision-style {
                text-decoration: none;
                margin-bottom: 15px;
                background-color: unset;
                box-shadow: unset;  
                 li {
                    list-style-type: none;
                    .emision-item {
                        border-radius: 0.9rem;
                        font-size: 13px;
                        margin: 3px;
                        padding-left: 8px;
                        margin-left: 20px;
                        background-color: #e5e5e5;
                        color: #2c4c57;
                        display: block;
                        padding: 2px 10px 2px 8px;
                    }
                }          
            }             
        }
        h4 {
            font-size: 20px;
            font-weight: 400;
        }
        
        a {
            font-size: 16px;
            color: #000;
            margin-bottom: 15px;
        }
        

        .color-menu {
            border-left: 7px solid  $turq;
            padding-left: 5px;
        }

        .color-menu-selected {
            background-color: $turq !important;
            color: $white !important;
            border-radius: 0.9rem;
            padding: 3px 8px 3px 8px;
            display: inline-block;
            margin-bottom: 0;
        }

        .total-syle {
            border-left: 7px solid $redDavivienda;
            padding-left: 5px;
           
        }
        .total-style-selected {
            background-color: $redDavivienda;
            color: $white !important;
            border-radius: 0.9rem;
            padding: 3px 8px 3px 8px;
        }
        
    
    }
}

.calc-body {



    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;  
    min-height: calc(100vh - 165px);
    max-height: calc(100vh - 165px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    .months-years{
        position: relative;
        width: 100%;
        .title-question {
            padding: 50px 15px;
            font-size: 18px!important;
        }
    }
     
}