.form-indicator, .monthly-form {
    .page-info {
        float: left;
        padding-left: 8px;
        font-size: 14px;
        font-weight: 400; 
    }
    
}
.form-box .indicator {
   
   .gra-flex{
       width: 22%;
   }
}
.form-indicator {
    .form-box {
         max-height: 47rem;
        overflow: auto;
        margin-bottom: 0px; 
        margin-top: 8px;
        .gra-flex{
            width: 22%;
        }
    }
    .btn--red {
        min-width: 100px;
        padding: 6px 12px;
        .fa-plus-circle {
           
            color: $white;
        }
    }
    .table {
      margin: 0;
    } 
    .loading {
        text-align: center;
    }
}

.monthly-form {
    .img-dropdown{
        height: 20px;
    } 

    .table-container {
        max-height: 87rem;
        overflow: auto;  
    }
    .table-container::-webkit-scrollbar {
        display: none;
         width: 0 !important;
         
     }

    table .loading {
        text-align: center;
    }
    .btn--red {
        min-width: 100px;
        padding: 6px 12px;
    }
}

.certification-calculator{
    flex-direction: column;
    .certification-content{
        display: flex;
        .dashboard{
            width: 27.8rem;
            max-height: calc(100vh - 17.8rem);
            min-height: calc(100vh - 17.8rem);
            .menu{
                padding-left: 2rem;
            }
        }
        .container-display{
            width: calc(100% - 27.8rem);
            max-height: calc(100vh - 17.8rem);
            min-height: calc(100vh - 17.8rem);
            .calc-body{
                min-height: calc(100vh - 16.8rem);
                max-height: calc(100vh - 16.8rem);
            }
        }
    }
    
    
}