//calc-body-title
.filter-month {
    position: relative;
    top: -46px;
    left: 88%;
}


    select{
        border: 2px solid #d5dbdd !important;
       //  padding: 2px;    
         border-radius: 5px !important;
    }


.calc-subtitle-container {
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.btn-add-office {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
   
    width: 120px;
    padding-bottom: 4px;
    font-size: 10px;
    border: none;
    margin-left: 10px;
    margin-right: 10px;
    .size-img {
        width: 15px!important;
        height: auto!important;
         padding-bottom: unset; 
    }
}
.calc-subtitle {
    color: #000;
    border-left: 5px solid $redDavivienda;
    padding-left: 5px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.button-info {
    margin-left: 10px;
    color: black ;
    font-size: 1.5em;
}

.icon-info {
    color: white;
    font-size: 15px;
}

//cards
.calc-card {
    display: flex;
    background: $calc-card-background;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 380px;
    height: 250px;
    border: 1px solid $gray-davivienda;
    border-top: none;
    border-radius: 2px;
}

.calc-card-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 12%;
    width: 100%;
    background: $gray-davivienda;
}

.head-title {
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    margin: 0;
    margin-left: 10px;
}

.calc-card-body {
    display: flex;    
    flex-direction: column;
    flex-wrap: wrap;
    gap: 17px;
    padding: 10px;
    height: 82%;
    width: 100%;
    .item-calc-card {
        display: flex;
        justify-content: flex-start;
        width: 100%
    }
    .no-data{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        height: 100%;
        justify-content: center;
        > img {
            height: 47px;
        }
    }
    

}

.name-input {
    width: 220px;
    height: 24px;
    border: 1px solid #A4A7A9;
    outline: none;
    font-size: 13.5px;
}

.code-input {
    width: 80px;
    height: 24px;
    margin-left: 10px;
    border: 1px solid #A4A7A9;
    font-size: 13.5px;
    outline: none;
}

.btn-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $calc-card-edit;
    height: 24px;
    width: 60px;
    border-radius: 40px;
    margin-left: 10px;
    color: #fff;
    font-size: 12px;
    border: none;
}

.btn-remove {
    height: 24px;
    width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: $redDavivienda;
    border: none;
    color: $white-cal-davivienda;
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
}

.calc-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #DBDBDB;
    height: 20%;
    width: 95%;
    .pagination {
        display: flex;
        align-items: center;
    }
    
    .pagination > li > a {
        background-color: unset ;
        border: unset;
        font-size: 13px;
        padding: 0px 10px
    }
    .pagination > .active > a {
        background-color: unset  ;
        color: $gray-davivienda ;
        border-bottom: solid $redDavivienda;
    }
    .size-img {
        width: 13px!important;
        height: auto!important;
        padding-bottom: unset; 
    }
    .btn--red{
        display: flex;
        width: 190px !important;
        padding: 4px 4px 6px 4px;
        align-items: center;
       
    justify-content: center;
    gap: 10px;
        p{
            font-size: 11px;
        }
    }
}

.btn-add {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    border-radius: 40px;
    color: #fff;
    font-size: 11px;
    border: none;
}

//info
.calc-general-container {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    margin: 20px;
    input {
        border: 2px solid #d5dbdd !important;
       // padding: 4px 1px;    
        border-radius: 5px !important;
    }
    input:focus {
        border: 2px solid black !important;
    }
}

.general-input-group {
    display: flex;
    flex-direction: column;
   

}

.general-input-group-save {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
}

.general-input-subtitle {
    color: #000;
    font-size: medium;
    font-weight: 500;
}

.general-input {
    border: solid 1px #A4A7A9;
    border-radius: 2px;
    border-width: thin;
    outline: none;
    width: 250px;
    color: #000;
    font-weight: 300;
    font-size: medium;
}

//inventory
.calc-windows-group {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 20px;
    input {
        border: 2px solid #d5dbdd !important;
       // padding: 4px 1px;    
        border-radius: 5px !important;
    }
    input:focus {
        border: 2px solid black !important;
    }
}

//inventory emissions
.inventory-emissions-container {
    input {
        border: 2px solid #d5dbdd !important;
       // padding: 4px 1px;    
        border-radius: 5px !important;
    }
    input:focus {
        border: 2px solid black !important;
    }
    width: 100%;
    .select-type {
        display: flex;
        align-items: center;
        background-color: #F7F7F7;
        padding: 10px;
        margin-bottom: 20px;
        color: #000;
        h4 {
            margin-right: 10px;
        }
        .btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 400px;
            padding: 2px;
            border-radius: 2px;
        }
        .btn-primary {
            width: 400px;
            font-weight: 300;
            background: $white;
            color: #000;
            border-color: #000;
        }
        .open>.dropdown-toggle.btn-primary:focus,
        .open>.dropdown-toggle.btn-primary:hover {
            background-color: #fff;
            color: #000;
            width: 400px;
        }
        .open>.dropdown-menu {
            display: block;
            width: 230px;
        }
    }
    .select-input {
        height: 25px;
        width: 30%;
        margin-left: 10px;
        outline: none;
        font-size: medium;
    }
    .equipment {
        display: flex;
        flex-wrap: wrap;
        color: #000;
        background-color: #f7f7f7;
        >div {
            display: flex;
        }
    }
    .equipment-subtitle {
        font-size: medium;
        margin-left: 10px;
        color: #000;
    }
    .equipment-data {
        display: flex;
        padding: 10px;
        width: 88%;
        flex-direction: column;
        background-color: #F7F7F7;
    }
    .equipment-data-select {
        display: flex;
        width: 100%;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: flex-end;
        .round_trip {
            display: flex;
            align-items: flex-start;
            gap: 8px;
        }
        input[type="radio"] {
            --active: #275EFE;
            --active-inner: #fff;
            --focus: 2px rgba(39, 94, 254, .3);
            --border: #BBC1E1;
            --border-hover: #275EFE;
            --background: #fff;
            --disabled: #F6F8FF;
            --disabled-inner: #E1E6F9;
            -webkit-appearance: none;
            -moz-appearance: none;
            height: 21px;
            outline: none;
            display: inline-block;
            vertical-align: top;
            position: relative;
            margin: 0;
            cursor: pointer;
            border: 1px solid var(--bc, var(--border));
            background: var(--b, var(--background));
            transition: background .3s, border-color .3s, box-shadow .2s;
            &:after {
                content: '';
                display: block;
                left: 0;
                top: 0;
                position: absolute;
                transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
            }
            &:checked {
                --b: var(--active);
                --bc: var(--active);
                --d-o: .3s;
                --d-t: .6s;
                --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
            }
            &:hover {
                &:not(:checked) {
                    &:not(:disabled) {
                        --bc: var(--border-hover);
                    }
                }
            }
            &:focus {
                box-shadow: 0 0 0 var(--focus);
            }
            &:not(.switch) {
                width: 21px;
                &:after {
                    opacity: var(--o, 0);
                }
                &:checked {
                    --o: 1;
                }
            }
            &+label {
                font-size: 14px;
                line-height: 21px;
                display: inline-block;
                vertical-align: top;
                cursor: pointer;
                margin-left: 4px;
            }
        }
        input[type="radio"] {
            &:not(.switch) {
                border-radius: 7px;
                &:after {
                    width: 5px;
                    height: 9px;
                    border: 2px solid var(--active-inner);
                    border-top: 0;
                    border-left: 0;
                    left: 7px;
                    top: 4px;
                    transform: rotate(var(--r, 20deg));
                }
                &:checked {
                    --r: 43deg;
                }
            }
        }
        .btn {
            min-width: 90px;
            max-width: 145px;
            background: #fff;
            color: #000;
            padding: 2px;
            border-color: #7D7D7D;
            font-weight: 400;
            font-size: 14px;
        }
        .open>.dropdown-toggle.btn-primary:focus,
        .open>.dropdown-toggle.btn-primary:hover {
            background-color: #fff;
            color: #000;
        }
    }
    .select-input-equipment {
        height: 25px;
        min-width: 80px;
        font-size: 12px;
        outline: none;
    }
    .select-input-equipment-disable {
        background-color: #D3D6D9;
        border: none;
        height: 25px;
        min-width: 80px;
        //margin-left: 10px;
        font-size: 12px;
    }
    .equipment-subtitle {
        font-size: 15px;
        width: max-content;
    }
    .equipment-total {
        width: 12%;
        background-color: #f7f7f7;
        display: flex;
        padding: 10px;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 13px;
        p {
            font-size: medium;
        }
    }
    .equipment-total-value {
        background-color: transparent;
        border: none !important;
        border-bottom: 1px solid !important;
        border-radius: unset !important;
        height: 25px;
        width: 100%;
        font-size: 12px;
        outline: none;
    }
    .equipment-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .btn-add-office {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
       
        color: $white;
        border: none;
    }
    .btn-delete {
        margin-right: 10px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 20px;
        width: 100px;
        border-radius: 40px;
        color: #fff;
        font-size: 10px;
        border: none;
        background-color: #9A9898;
    }
    .btn-office {
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 158px;
        border-radius: 1.3rem;
        color: #fff;
        font-size: 16px;
        border: none;
        background-color: $redDavivienda;
        padding: 4px;
    }
    .office {
        display: flex;
        color: #000;
        flex-direction: column;
        background-color: #F7F7F7;
        padding: 10px;
    }
    .office-data {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .office-data-row {
        display: flex;
        width: 80%;
    }
    .office-data-select {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(3, 1fr);
    }
    .office-input-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        p {
            font-size: 15px;
        }
    }
    .office-input {
        height: 25px;
        width: 130px;
        font-size: 12px;
        outline: none;
    }
    .office-input-direction {
        margin-left: 20px;
        font-size: 15px;
    }
    .office-input-country {
        max-width: 130px;
    }
    .office-total {
        display: flex;
        flex-direction: column;
        border-left: 2px solid #000;
        padding: 20px;
        justify-content: center;
        padding-right: 39px;
        width: 20%;
    }
    .direction-input {
        font-size: 15px;
        width: 282px;
        height: 114px;
        max-height: 200px;
        outline: none;
        resize: none;
    }
    .measurer {
        margin-top: 30px;
        padding: 10px;
        background-color: #e4e4e4;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        .office-total-measurer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-left: 1px solid #000;
            padding-left: 20px;
            margin-left: 20px;
        }
    }
    .btn-green {
        transition: .3s;
        height: 40px;
        width: 40px;
        position: relative;
        border-radius: 20px;
        background-color: #D3D6D9;
        border: solid 3px #7D7D7D;
        margin-left: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #7D7D7D;
    }
    .btn-green-selected {
        transition: .3s;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        background-color: #8DC466;
        border: solid 3px #8DC466;
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #fff;
    }
}

//emission register
.emission-container {
    color: #000;
    input {
        border: 2px solid #d5dbdd !important;
       // padding: 4px 1px;    
        border-radius: 5px !important;
    }
    input:focus {
        border: 2px solid black !important;
    }
    .emission-register-container {
       
        padding: 20px;
        background-color: #F7F7F7;
        .content-data+.content-data {
            margin-top: 4rem;
        }
    }
    .content-data {
        display: flex;
        margin-left: 5rem;
        .title-data {
            width: 15%;
        }
        .form-data {
            width: 85%;
        }
        
    }
    .subtitle {
        margin-left: 5rem;
        margin-bottom: 2rem;
    }
    .emission-data {
        display: flex;
        .evidence-subtitle {
            max-width: 202px;
            height: 30px;
            text-align: center;
        }
    }
    .resume-data {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .tooltip-inner {
          max-width: 600px !important;
        }
        



        .evidence-subtitle {
            max-width: 202px;
            height: 30px;
            text-align: center;
        }
        .m-auto {
            width: 100%;
            .electrical-subtitle-totals {
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 1rem;
            }
        }
    }
    .emission-subtitle-data {
        font-size: 15px;
        font-weight: 400;
        max-width: 150px;
    }
    .emission-input-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;
        margin-top: 0;
    }
    .emission-subtitle {
        font-size: 14px;
    }
    .emission-input {
        height: 25px;
        min-width: 140px;
        max-width: 150px;
        font-size: 13.3px;
        outline: none;
    }
    .btn-check-emission {
        height: 49px;
        width: 40px;
        border-radius: 20px;
        position: relative;
        margin-top: 3%;
        margin-left: -5%;
        font-size: 21px;
        color: red;
    }
    .line {
        height: 20px;
        margin-left: 10px;
        width: 90%;
        border-top: 2px solid #DDDDDD;
    }
    .evidence {
        display: flex;
    }
    .evidence-input-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        margin: 10px;
        margin-top: 0;
        gap: 15px;
        .emission-input-check {
            display: flex;
            justify-content: space-between;
        }
    }
    .evidence-subtitle {
        font-size: 14px;
    }
    .evidence-subtitle-check {
        font-size: 13px;
        margin-right: 4px;
    }
    .style-disabled {
        border: none;
        background-color: #DFE8EB;
    }
    .emission-input-edit {
        height: 25px;
        min-width: 202px;
        font-size: 12px;
        outline: none;
    }
    .evidence-input {
        border: none;
        background-color: #DFE8EB;
        height: 25px;
        min-width: 207px;
        font-size: 13.3px;
        outline: none;
    }
    .evidence-button-file {
        border: none;
        background-color: #EBEBEB;
        height: 25px;
        width: 250px;
        font-size: 12px;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #8EB4ED;
    }
    .emission-resume-container {
        .see-more{
            .open_list{
                background-color: $redDavivienda;
                width: 150px;
                border-radius: 1.3rem;
                color: #fff;
                font-size: 15px;
                border: none;
                padding: 2px;
            }
            display: flex;
            justify-content: flex-end;
             a{
                cursor: pointer;
                font-size: 15px;
            } 
        }
        table{
            width: 100%;
            .general-title{
             font-weight: bold;
            }
            background-color: white;
         }
        padding: 20px;
        background-color: #F7F7F7;
    }
    .resume {
        display: flex;
        width: 100%;
    }
    .emission-resume-total-container {
        padding: 20px;
        background-color: #F7F7F7;
        width: 100%;
        margin-top: 20px;
        
    }
    .btn-add-office {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: $redDavivienda;
        width: 150px;
        border-radius: 1.3rem;
        color: $white;
        font-size: 16px;
        border: none;
        padding: 4px;
    }
    .equipment-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        margin-bottom: 20px;
        gap: 15px;
    }
    //Datos del electricidad
    .electrical-data-select {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
        flex-wrap: wrap;
        .electrical-input {
            min-width: 156px;
            max-width: 190px;
            font-size: 13px;
            outline: none;
            height: -webkit-fill-available;
        }
    }
    .electrical-subtitle {
        font-size: 13px;
    }
    .electrical-input-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3px;
        gap: 3px;
    }
    .electrical-line {
        height: 20px;
        border-top: 1px solid #777;
        width: 93%
    }
    .electrical-input-dark {
        background-color: #DFE8EB;
        width: 100%;
        font-size: 13px;
        outline: none;
        border: none;
        margin-bottom: 15px;
    }
    .electrical-months-data {
        display: flex;
        flex-direction: column;
        .electrical-input {
            max-width: 126px;
            font-size: 13px;
            outline: none;
        }
        .months-top {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            justify-content: flex-start;
            gap: 3rem;
        }
        .months-data {
            display: flex;
            margin-bottom: 20px;
            flex-wrap: wrap;
        }
        .months-subtitle-data {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

//total months
.months-total {
    padding: 20px;
    background-color: #F7F7F7;
    color: #000;
    font-size: 15px;
    div {
        justify-content: flex-start;
    }
    .top-container {
        input {
            border: 2px solid #d5dbdd !important;
           // padding: 4px 1px;    
            border-radius: 5px !important;
        }
        input:focus {
            border: 2px solid black !important;
        }
        display: flex;
        flex-direction: row;
        margin-bottom: 3rem;
    }
    .line {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 4rem;
    }
    .months-subtitle-data {
        display: flex;
        justify-content: flex-start;
        font-size: 18px;
        font-weight: 500;
        width: 100%;
    }
    .months-subtitle {
        font-size: 16px;
        font-weight: 400;
    }
    .months-input-group {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .months-input-dark {
        background-color: #DFE8EB;
        width: 100%;
        outline: none;
        border: none;
    }
    .months-input-total {
        background-color: #F7F7F7;
        border: none;
        border-bottom: 1px solid #cdcfd0;
        width: 100%;
        font-size: 15px;
        outline: none;
    }
    .months-icon-plus {
        margin-top: 2.6rem;
        color: #a5a8a9;
    }
    .months-line {
        height: 20px;
        border-top: 1px solid #777;
    }
    .months-buttons {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .month-btn {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-left: 15px;
        background-color: #7d7d7d;
        height: 28px;
        width: 170px;
        border-radius: 40px;
        color: #fff;
        font-size: 14px;
        border: none;
    }
}