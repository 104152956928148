
.form--card {
	[type="submit"] {
		display: block;
		width: 100%;
		background-color: $blue;
		border: none;
		color: #FFF;
		line-height: 48px;
		padding: 0;
		margin-bottom: 30px;
	}
}
.container-fluid  > .card{
	padding-bottom: 15px;

}
.card {
	background-color: #FFF;
        margin-top: 15px;
        margin-bottom: 15px;
	
    //padding-bottom: 15px;
	@include box-shadow (0 , 2px , 4px , 0 ,  rgba(0,0,0,0.2));
}

.card--closed .card__inner {
	padding: 0 40px;
	.card__title {
		margin: 0;
	}
}

.card__inner {
	padding: 20px;
	position: relative;
	
	 & + & {
		border-top: 1px solid #d6d6d6;
	} 
	.btn--positioned {
		margin-top: 20px;
	}
	.modal-btns {
		display: flex;
		align-items: baseline;
	}
	.fa-plus-circle {
		font-size: 22px;
		color:  #01AED9;
		display: inline-block;
		vertical-align: middle;
	}


	
}

h4.panel-title  .card__inner {
	&::before{
		content: '';
		height: 100%;
		width: 4px;
		position: absolute;
		top: 0;
		left: 0px;
		background-color: $turq;
	}
	&.red::before {
		background-color: $red !important;
	}
	&.orange::before {
		background-color: $orange !important;
	}
}
.ng-scope span.ng-binding +  .card__inner {
	&::before{
		content: '';
		height: 100%;
		width: 4px;
		position: absolute;
		top: 0;
		left: 0px;
		background-color: $turq;
	}
	&.red::before {
		background-color: $red !important;
	}
	&.orange::before {
		background-color: $orange !important;
	}
}
.card__title {
	font-size: 22px;
	line-height: 40px;
	position: relative;
	margin-bottom: 20px;
/* 	&::before {
		content: '';
		height: 100%;
		width: 4px;
		position: absolute;
		top: 0;
		left: -20px;
		background-color: $turq;
	} 
	 &.red::before {
		background-color: $red !important;
	}
	&.orange::before {
		background-color: $orange !important;
	}  */
	a {
		color: $black;
		display: block;
		@extend .clearfix;
		&:hover,&:focus {
			outline: none;
			text-decoration: none;
		}
	}
	.card__icon {
		float: right;
		line-height: 40px;
		font-size: 22px;
	}
}

.card__title-simple {
	@extend .card__title;
	&::before {
		content: none;
	}
}

.card__subtitle {
	font-size: 17px;
	margin-bottom: 30px;
}

.card__heading {
	font-weight: 700;
	font-size: 16px;
	text-transform: uppercase;
	& + & {
		margin-top: 25px;
	}
}

.card__subheading {
	font-size: 14px;
	font-weight: 400;
}

.card__text {
	font-weight: bold;
    margin-bottom: 10px;
	margin: 10px 0 20px;
	[class^="fa"] {
		color: $redDavivienda;
	}
}

.card--message {
	.card__inner {
		padding: 60px;
	}
	h3 {
		margin-bottom: 15px;
		font-size: 26px;
	}
	p {
		font-size: 16px;
		margin-bottom: 40px;
	}
}

.panel {
	.card__inner {
		padding: 7px 40px;
		transition: all 0.5s;
	}
	.card__title {
		margin: 0;
		display: flex;
        justify-content: space-between;
	}
}

.panel-open {
	.card__inner {
		padding: 40px;
		transition: all 0.5s;
	}
	.card__title {
		margin-bottom: 20px;
		display: flex;
        justify-content: space-between;
		
	}
	.card__title > div > button {
		margin-left: -24%;		
	}
}

.card--register {
	position: relative;
	h2 {
		font-weight: bold;
		font-size: 22px;
		position: relative;
		margin: 40px 0;
		&::before {
			content: '';
			height: 100%;
			width: 4px;
			position: absolute;
			top: 0;
			left: -40px;
			background-color: $turq;
		}
		}
		.register__text  {
			h2{
				&::before {
					left: -10px !important;
				}
			}
		}
	.btn--blue {
		display: block;
		width: 139px;
		margin: 35px auto 0; 
		font-size: 25px;
	}

	.btn--red {
		display: block;
		font-size: 16px;
	}
	 &::before {
		content: '';
		width: 251px;
		height: 306px;
		position: absolute;
		bottom: -70px;
		left: -161px;
		background-image: url('../img/content/bg.png');
		background-repeat: no-repeat;
		background-size: cover;
		z-index: -1;
	} 
}

.card--login {
	margin: 42px auto;
	position: relative;
	max-width: 460px;
	box-shadow: none;
	.card__inner{
		box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	}
	
	h1 {
		font-weight: bold;
		font-size: 22px;
		position: relative;
		margin: 40px 0;
		/* &::before {
			content: '';
			height: 100%;
			width: 4px;
			position: absolute;
			top: 0;
			left: -40px;
			background-color: $turq;
		} */
	}
	.btn--blue {
		display: block;
		margin: 35px auto 0; 
		max-width: none;
		text-transform: uppercase;
		
	}
	.btn--red {
		display: block;
		//width: 100%;
		margin: 35px auto 0; 
		max-width: none;
		border-radius: 10px;
		//text-transform: uppercase;
	}
	.checkbox {
		 + label {
			margin-top: 15px;
			font-size: 16px;
		}
	}
	&::before {
		content: '';
		width: 251px;
		height: 306px;
		position: absolute;
		bottom: -70px;
		right: -161px;
		background-image: url('../img/content/bg.png');
		background-repeat: no-repeat;
		background-size: cover;
		z-index: -1;
	}
}

.card--btn {
	display: block;
	padding: 40px;
	margin: 15px 0;
	color: $black;
	font-weight: 700;
	cursor: pointer;
	@include box-shadow (0 , 2px , 4px , 0 ,  rgba(0,0,0,0.2));
	[class^="fa"] {
		display: block;
		font-size: 52px;
		margin-bottom: 15px;
		color: $blue;
	}
	.title {
		margin-bottom: 10px;
	}
}

.card--btn__logo {
	width: 100px;
	height: 100px;
	margin: 0px auto;
	div {
		background-color: #8CC249;
		line-height: 100px;
		text-align: center;
		color: #FFF;
		text-transform: uppercase;
		font-size: 32px;
	}
	img {
		display: block;
		width: 100%;
                height: 100px;
	}
        &+p {
            text-align: center;
        }
}

.card--progress {
	margin: 15px 0;
	.btn {
		margin-top: 20px;
		width: 100%;
		min-width: auto;
	}
	.title {
		font-size: 17px;
		font-weight: 700;
	}
	[class^="fa"] {
		font-size: 30px;
	}
}

.card__table {
	width: 100%;
	font-size: 17px;
	table-layout: fixed;
	thead {
		font-size: 16px;
		background-color: $gray-lightest;
	}
	th, td {
		padding: 10px;
		&:last-child {
			text-align: center;
		}
	}
	tr{
		&:nth-child(even) {
			background-color: $gray-lightest;
		}
	}
}

.card__btn-full {
	width: 100%;
	padding: 15px;
	background-color: $gray-lighter;
	color: $orange;
}

@media screen and (min-width: 768px) {
	.card__table {
		table-layout: auto;
		th, td {
			padding: 15px;
			&:first-child {
				padding-left: 40px;
			}
			&:last-child {
				text-align: left;
    			padding-right: 7px;
			}
		}
	}
	.card--btn {
		min-height: 200px;
	}
	.card__inner {
		padding: 20px 40px;
                overflow: visible;
		.btn--positioned {
			margin: 0;
			position: absolute;
			top: 20px;
			right: 40px;	
		}
	}
	.card__title {
		&::before {
			left: -40px;
		}
	}
}
